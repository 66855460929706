<template>
  <li class="nav-item mr-sm-1 mr-md-0 calendar-icon">
    <router-link to="/calendar" class="d-flex align-items-start ">
      <feather-icon icon="CalendarIcon" class="text-body" size="18"/>
    </router-link>
  </li>
</template>

<script>
export default {
  name: "Calendar"
}
</script>

<style scoped>
@media screen and  (max-width: 560px) {
  .calendar-icon {
    display: none;
  }
}


</style>