export default [
  {
    header: 'Account',
  },
  {
    title: 'Profile',
    icon: 'UserIcon',
    route: 'profile',
  },
  {
    title: 'Payout methods',
    icon: 'CreditCardIcon',
    route: 'payment-method-list',
  },
  {
    header: 'Overview',
  },
  {
    title: 'Payout credit memos',
    icon: 'DollarSignIcon',
    route: 'payout',
  },
  {
    title: 'Payout history',
    icon: 'DollarSignIcon',
    route: 'payout-history-list',
  },
  {
    title: 'Credits',
    icon: 'DollarSignIcon',
    route: 'bookingtransactions-list',
  },
]
