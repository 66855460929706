<template>
  <b-badge pill :variant="badgeColor" class="mx-md-1" >
   <span>{{ $t('Credit memos') }}:</span>
    {{ BALANCE }} <span class="currency_symbol">&euro;</span>
  </b-badge>
</template>

<script>
import {BBadge,} from 'bootstrap-vue'
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Credits",
  components: {
    BBadge
  },
  data() {
    return {
      balance: 0
    }
  },
  mounted() {
    this.GET_BALANCE()
  },
  methods: {
    ...mapActions(['GET_BALANCE']),
  },
  computed: {
    ...mapGetters(['BALANCE']),
    badgeColor() {
      if (this.credits < 200) return 'danger'
      if (this.credits >= 200 && this.credits < 500) return 'warning'
      if (this.credits >= 500) return 'success'
      return 'primary'
    }
  }
}
</script>
